import styled from '@emotion/styled';
import { css, Theme } from '@emotion/react';
import { motion } from 'framer-motion';
import { switchProp } from 'styled-tools';
import type { ButtonArea, ButtonProps, ButtonShade, ButtonSize } from './Button';
import { breakpoints } from '~/theme';
import { shouldNotForwardProps } from '~/shared/utils/styled';

const ResetStyle = css({
    alignItems: 'center',
    appearance: 'none',
    border: 'none',
    boxSizing: 'border-box',
    display: 'inline-flex',
    cursor: 'pointer',
    flexShrink: 0,
    flexGrow: 1,
    fontFamily: 'inherit',
    lineHeight: 1,
    letterSpacing: 0,
    margin: 0,
    padding: 0,
    textRendering: 'inherit',
    textDecoration: 'none',
    WebkitUserSelect: 'none',
    userSelect: 'none',
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    borderRadius: 0,
    backgroundColor: 'transparent',
    color: 'inherit',
    outlineOffset: '0px',
    width: '100%',
});

export type StyledButtonProps = Omit<ButtonProps, 'iconAlignment' | 'iconLabel' | 'iconOffsetEdge'>;

const centerAlignedOffset = 0.2;
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const StyledElm = (component: any) =>
    styled(component, {
        shouldForwardProp: shouldNotForwardProps(['iconAlignment']),
    })<StyledButtonProps>(
        ({ theme, shade }) => {
            const isDark = shade === 'dark';
            return {
                position: 'relative',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '1px 0 0 0',
                textAlign: 'center',
                overflow: 'hidden',
                transition: `
                    background-color ${theme.animations.timingMedium} ${theme.animations.easeSmooth},
                    color ${theme.animations.timingMedium} ${theme.animations.easeSmooth}
                `,
                ...theme.fontVariants.button,

                figure: {
                    marginTop: `-${centerAlignedOffset}rem`, // NOTE: Sadly needed to align icon proper to text
                    transition: `color ${theme.animations.timingMedium} ${theme.animations.easeSmooth}`,
                },

                ':disabled': {
                    opacity: 0.5,
                    cursor: 'not-allowed',
                    pointerEvents: 'none',
                },

                ':before': {
                    content: '""',
                    position: 'absolute',
                    backgroundColor: isDark ? theme.colors.white : theme.colors.red,
                    top: -10,
                    left: -10,
                    right: -10,
                    bottom: -10,
                    transform: 'translate3d(0, 100%, 0)',
                    borderRadius: '100%',
                    transformOrigin: 'center',
                    transition: `
                    border-radius ${theme.animations.timingLonger} ${theme.animations.easeSmooth},
                    transform ${theme.animations.timingLong} ${theme.animations.easeSmooth}
                `,
                },
            };
        },
        switchProp('variant', {
            Red: ({ theme, shade }: { theme: Theme; shade: ButtonShade }) => {
                const isDark = shade === 'dark';
                return {
                    outline: `1px solid ${theme.colors.red}`,
                    borderRadius: theme.borderRadius.button,
                    backgroundColor: isDark ? 'transparent' : theme.colors.white,
                    color: isDark ? theme.colors.white : theme.colors.black,

                    figure: {
                        color: isDark ? theme.colors.white : theme.colors.red,
                    },
                    ':after': {
                        content: "''",
                        position: 'absolute',
                        zIndex: -1,
                        width: '100%',
                        height: '100%',
                        backgroundColor: theme.colors.red,
                        top: 0,
                        left: 0,
                    },

                    '&:hover': {
                        outline: `1px solid ${theme.colors.red}`,
                        color: isDark ? theme.colors.red : theme.colors.white,

                        ':before': {
                            transform: 'translate3d(0, 0%, 0)',
                            borderRadius: 0,
                        },

                        figure: {
                            color: isDark ? theme.colors.red : theme.colors.white,
                        },
                    },
                };
            },
            Transparent: ({ theme, shade }: { theme: Theme; shade: ButtonShade }) => {
                const isDark = shade === 'dark';
                return {
                    borderRadius: theme.borderRadius.button,
                    backgroundColor: 'transparent',
                    outline: isDark
                        ? `1px solid ${theme.colors.black}`
                        : `1px solid ${theme.colors.white}`,
                    color: isDark ? theme.colors.black : theme.colors.white,
                    figure: {
                        color: isDark ? theme.colors.red : theme.colors.white,
                    },

                    ':before': {
                        transform: 'translate3d(0, 100%, 0)',
                        borderRadius: '100%',
                        backgroundColor: isDark ? theme.colors.black : theme.colors.white,
                    },

                    '&:hover': {
                        color: isDark ? theme.colors.white : theme.colors.black,

                        ':before': {
                            transform: 'translate3d(0, 0%, 0)',
                            borderRadius: 0,
                        },

                        figure: {
                            color: isDark ? theme.colors.white : theme.colors.black,
                        },
                    },

                    '&:active': {},
                };
            },
            Plain: ({ theme, shade }: { theme: Theme; shade: ButtonShade; size: ButtonSize }) => {
                const isDark = shade === 'dark';
                // Plain has just dark mode according to design
                return {
                    height: 'auto',
                    minHeight: 'auto',
                    boxShadow: 'none',
                    padding: '0.2em 0.3em',
                    margin: '0 -0.2em',
                    // textDecoration: 'underline',
                    borderRadius: 0,
                    color: isDark ? theme.colors.black : theme.colors.white,
                    fontSize: theme.fontSizes.xs,

                    figure: {
                        color: isDark ? theme.colors.black : theme.colors.white,
                    },

                    ':before': {
                        content: 'none',
                    },

                    ':hover': {
                        textDecoration: 'none',
                        color: theme.colors.red,

                        figure: {
                            color: theme.colors.red,
                        },
                    },
                };
            },
            Ghost: ({ theme }: { theme: Theme }) => {
                return {
                    borderRadius: theme.borderRadius.button,
                    backgroundColor: theme.colors.grey05,
                    color: theme.colors.black,
                };
            },
        }),
        switchProp('size', {
            Big: ({ theme }: { theme: Theme }) => {
                return {
                    minHeight: '5rem',
                    padding: `calc(${theme.spaces[4]} + ${centerAlignedOffset}rem) ${theme.spaces[6]} ${theme.spaces[4]}`,
                };
            },
            Small: ({ theme }: { theme: Theme }) => {
                return {
                    padding: `calc(${theme.spaces[2]} + ${centerAlignedOffset}rem) ${theme.spaces[4]} ${theme.spaces[2]}`,
                };
            },
            Inline: () => {
                return {
                    padding: '0 2px 0 2px',
                    fontSize: 'inherit',
                };
            },
            Icon: ({ theme }: { theme: Theme }) => ({
                minHeight: '3.2rem',
                minWidth: '3.2rem',
                padding: `calc(${theme.spaces[1]} + ${centerAlignedOffset}rem) ${theme.spaces[3]} ${theme.spaces[1]}`,
                borderRadius: 6,
                [breakpoints.sm]: {
                    minWidth: '5rem',
                    minHeight: '5rem',
                    borderRadius: 15,
                },
            }),
        }),
        switchProp('iconAlignment', {
            center: ({ theme }) => {
                return {
                    '.centered-icon-button-text': {
                        opacity: 0,
                        transition: `opacity ${theme.animations.timingLong} ${theme.animations.easeSmooth}`,
                    },
                    figure: {
                        opacity: 1,
                        transition: `opacity ${theme.animations.timingLong} ${theme.animations.easeSmooth}`,
                    },

                    ':hover': {
                        '.centered-icon-button-text': {
                            opacity: 1,
                            transition: `opacity ${theme.animations.timingLong} ${theme.animations.easeSmooth}`,
                        },
                        figure: {
                            opacity: 0,
                            transition: `opacity ${theme.animations.timingLong} ${theme.animations.easeSmooth}`,
                        },
                    },
                };
            },
        }),
    );

export const StyledResetButton = styled(motion.button)({
    ...ResetStyle,
});

export const StyledResetLink = styled(motion.a)({
    ...ResetStyle,
});

export const StyledButton = StyledElm(StyledResetButton);
StyledButton.displayName = 'StyledButton';
export const StyledLinkComponent = StyledElm(StyledResetLink);
StyledLinkComponent.displayName = 'StyledLinkComponent';

export const StyledButtonWrapper = styled.div<{ area: ButtonArea }>(
    () => ({
        display: 'inline-flex',
        willChange: 'transform',
    }),
    switchProp('area', {
        '100': () => {
            return {
                width: '100%',
            };
        },
    }),
);

export const StyledButtonContent = styled(motion.span)<{ size: ButtonSize }>(
    ({ theme }) => ({
        position: 'relative',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        [breakpoints.xs]: {
            textAlign: theme.direction === 'rtl' ? 'right' : 'left',
            whiteSpace: 'unset',
        },
    }),
    switchProp('size', {
        Big: ({ theme }) => {
            return {
                gap: theme.spaces[4],
            };
        },
        Small: ({ theme }) => {
            return {
                gap: theme.spaces[2],
            };
        },
    }),
);

export const StyledCenteredIconButtonText = styled.span({});

export const StyledCenteredIconButtonIcon = styled.div(({ theme }) => ({
    position: 'absolute',
    width: theme.sizes.iconLg,
    height: theme.sizes.iconLg,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
